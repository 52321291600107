/*
 * Copyright Schrodinger, LLC
 */

/**
 * An enum for handling meta data around different api entities.
 *
 * NOTE(cheng): Each entity is a relative path to the root api endpoint, e.g:
 *              scaffolds -> http://localhost:9080/livedesign/api/scaffolds
 *              These paths should be used in conjunction with
 *              bbConsts.WEBSERVICE_ROOT
 *
 * TODO (jordan) Make a pass through here and establish better standards
 *
 * @enum {string}
 */
const ApiEndpoints = {
  ABOUT: 'about',
  ACTIVE_USERS: 'active_users',
  ADD_TAG: 'tags/live_reports',
  ADDABLE_COLUMNS: 'addable_columns',
  ADDABLE_COLUMNS_VALIDATE: 'addable_columns/validate',
  ASSAY_LIMITED_ADDABLE_COLUMNS: 'assay_limited_addable_columns',
  ASSAY_LIMITED_ADDABLE_COLUMN_GROUPS: 'column_groups/create_limited_column_group',
  ASSAY_LIMITED_RELATED_COLUMNS: 'assay_limited_addable_columns/related_column_search',
  ASSAY_DESCRIPTION: 'assay_groups/description',
  ASSAY_METADATA: 'assay_metadata/assay',
  ASSAY_METADATA_OBSERVATION_KEYS: 'assay_metadata/keys/search',
  ASSAY_METADATA_KEYS: 'assay_metadata/keys',
  ASSAY_TOOLTIP_CONFIG_LIVE_REPORT_COLUMN: 'assay_tooltip_config/live_report_column',
  ASSAY_TOOLTIP_CONFIG_LIVE_REPORT_COLUMN_SEARCH: 'assay_tooltip_config/live_report_column/search',
  ASYNC_TASK: 'async_task',
  ATTACHMENT: 'attachment',
  AUDIT_LOG: 'audit_log',
  AUDIT_TRAIL: 'audit/values/search',
  AUTOSUGGEST_SEARCH: 'live_reports/autosuggest_search',
  BATCH: 'batch',
  BULK_CREATE_R_GROUPS_ASYNC: 'r_groups/create_from_sar_column/async',
  BULK_CREATE_R_GROUPS_ASYNC_RESULT: 'r_groups/create_from_sar_column/async/${taskId}/result',
  COLUMN_ALIAS: 'column_alias',
  COLUMN_FOLDER: 'column_folder/folders',
  COLUMN_FOLDER_NODES: 'column_folder/nodes',
  COLUMN_FOLDER_NODES_V2: 'column_folder/v2/nodes',
  COLUMN_GROUPS: 'column_groups/${liveReportId}', // Used only to PUT - get from RowInfo endpoint via columns_order field
  COLUMN_GROUPS_CREATE_LIMITED: 'column_groups/create_limited_column_group',
  COLUMN_GROUPS_UPDATE_LIMITED: 'column_groups/update_limited_column_group/${columnGroupId}',
  COLUMN_NODE: 'column_folder',
  COLUMN_NODE_V2: 'column_folder/v2',
  COMMENT: 'comments',
  COMMENT_SEARCH: 'comments/search',
  COMPOSITE_ROWS_RELATIONSHIP_METADATA:
    'entity_relationship/entity/${entityId}/relationship_type/${relationshipType}',
  COMPOUNDS: 'compounds',
  COMPOUNDS_CONVERT: 'compounds/convert',
  COMPOUNDS_IMAGE: 'compounds/image',
  COMPOUNDS_METADATA: 'compounds/metadata/${corporateId}',
  COMPOUNDS_STATISTICS: 'compounds/statistics',
  COMPOUNDS_SEARCH: 'compounds/search',
  COMPOUNDS_SEARCH_ASYNC: 'compounds/search/async',
  COMPOUNDS_SEARCH_ASYNC_RESULT: 'compounds/search/async/${taskId}/result',
  //NOTE (tchoi): Use along with bb.enums.RepresentationFormat
  COMPOUND_BY_FORMAT: 'compounds/${corporateId}/?format=${format}&project_id=${projectId}',
  CSV_FASTA_IMPORT: 'generic_entity/csv_fasta/import/async_task',
  DATABASE: 'databases',
  DATABASE_COLUMNS: 'database_columns',
  ENTITY_ALIAS_SEARCH: 'entity_alias/search',
  ENUMERATION_REACTANTS: 'reactants/search',
  ENUMERATION_REACTIONS: 'enumeration/reaction',
  ENUMERATION_REACTION_JOB: 'enumeration/reaction/async_task',
  ENUMERATION_R_GROUP: 'enumeration/r_group',
  ENUMERATION_R_GROUP_JOB: 'enumeration/r_group/async_task',
  EXPERIMENTS_DATASETS: 'experiments/datasets/search',
  EXPERIMENTS_IMPORT: 'experiments/import/async_task',
  EXPERIMENTS_IMPORT_RESULT: 'experiments/import/async_task/${task_id}/result',
  FAVORITE_COLUMNS: 'projects/${projectId}/favorites/columns',
  FAVORITE_COMPOUND: 'compounds/favorite',
  FILTER: 'live_reports/${id}/filter',
  FILTERS_SUBSTRUCTURE: 'filters/substructure',
  FORMULA: 'formulas',
  FORMULA_SEARCH: 'formulas/search',
  FREEFORM_ATTACHMENT: 'attachment',
  FREEFORM_COLUMNS: 'freeform_columns',
  FREEFORM_COLUMNS_CHECK_NAME: 'freeform_columns/check_name',
  GENERIC_ENTITY_IMPORT: 'generic_entity/import/async_task',
  GENERIC_ENTITY_IMPORT_LOT_PARSE: 'generic_entity/import/lot/parse',
  GENERIC_ENTITY_IMPORT_PARSE: 'generic_entity/import/parse',
  GENERIC_ENTITY_IMPORT_RESULT: 'generic_entity/import/async_task/${taskId}/result',
  GENERIC_ENTITY_LOT_DELETE_TEMPLATE: 'lot_property_import_template/${templateId}',
  GENERIC_ENTITY_LOT_IMPORT: 'generic_entity/import/lot/async_task',
  GENERIC_ENTITY_LOT_METADATA: 'entity_metadata/metadata_properties',
  GENERIC_ENTITY_LOT_NEW_TEMPLATE: 'lot_property_import_template',
  GENERIC_ENTITY_LOT_TEMPLATES: 'lot_property_import_template/list',
  GENERIC_ENTITY_SEARCH: 'generic_entity/search',
  GENERIC_ENTITY_STRUCTURE: 'generic_entity/structure',
  GENERIC_ENTITY_SUBSTRUCTURE_SEARCH: 'generic_entity/substructure/search',
  HEALTH: 'health',
  HEALTH_CLEAR: 'health/clear',
  HEALTH_EVENTS: 'health/events',
  HIGHLIGHTED_SUBSTRUCTURE: 'compounds/${corporateId}/highlighted_substructure', //?substructure=${substructure}',
  IMAGE: 'image',
  IMPORT: 'import/async_task',
  IMPORT_ASYNC_TASK_DOWNLOAD: 'import/async_task/${taskId}/download_receipt',
  IMPORT_ASYNC_TASK_RESULT: 'import/async_task/${taskId}/result',
  LANDING_PAGE_BOOKMARK: 'landingpages/bookmarks',
  LANDING_PAGE_RESOURCE: 'landingpages/resources',
  LAYOUT: 'layouts',
  LIGAND_DESIGNER_ASYNC_JOB: 'ligand_designer/task/search',
  LIGAND_DESIGNER_ASYNC_JOB_RESULT: 'ligand_designer/task/${taskId}',
  LIGAND_DESIGNER_CONFIGURATION: 'ligand_designer_configuration/columnar',
  LIGAND_DESIGNER_DOCK: 'ligand_designer/session/${parentId}/dock',
  LIGAND_DESIGNER_GROWTH_SPACE_REFERENCE_LIGAND:
    'ligand_designer/session/${parentId}/dock/reference/growth_space',
  LIGAND_DESIGNER_INTERACTION_MAP: 'ligand_designer/session/${parentId}/interaction_surface',
  LIGAND_DESIGNER_MINIMIZE: 'ligand_designer/minimize',
  LIGAND_DESIGNER_RECEPTOR_INTERACTIONS_REFERENCE_LIGAND:
    'ligand_designer/session/${parentId}/dock/reference/receptor_interactions',
  LIGAND_DESIGNER_SESSION: 'ligand_designer/session',
  LIGAND_DESIGNER_SESSION_ACTIVE: 'ligand_designer/session/${glideSessionId}',
  LIGAND_DESIGNER_SYNCHRONIZE: 'ligand_designer/synchronize',
  LIGAND_DESIGNER_WATERMAP: 'ligand_designer/session/${parentId}/watermap',
  LIVE_REPORT: 'live_reports',
  LIVE_REPORT_ADD_ALL_ASSAYS: 'live_reports/add_all_assays',
  LIVE_REPORT_APPLY_TEMPLATE_ASYNC: 'live_reports/apply_template/async',
  LIVE_REPORT_APPLY_TEMPLATE_ASYNC_RESULT: 'live_reports/apply_template/async/${taskId}/result',
  LIVE_REPORT_OVERWRITE_TEMPLATE:
    'live_reports/${liveReportId}/template/${oldTemplateId}/overwrite',
  LIVE_REPORT_COLUMN_DESCRIPTOR: 'column_descriptors',
  LIVE_REPORT_COMPOUNDS_SEARCH: 'live_reports_compounds/search',
  LIVE_REPORT_COPY: 'live_reports/${liveReportId}/copy',
  LIVE_REPORT_COPY_TO_PROJECT_ASYNC: 'live_reports/copy_to_project/async',
  LIVE_REPORT_COPY_TO_PROJECT_ASYNC_RESULT: 'live_reports/copy_to_project/async/${taskId}/result',
  LIVE_REPORT_COPY_TO_PROJECT_PREVIEW: 'live_reports/copy_to_project/preview',
  LIVE_REPORT_EXPORT: 'live_reports/export',
  LIVE_REPORT_GRID_SEARCH: 'live_reports/grid_search',
  LIVE_REPORT_MERGE_LIVE_REPORTS_ASYNC: 'live_reports/merge_live_reports/async',
  LIVE_REPORT_MERGE_LIVE_REPORTS_ASYNC_RESULT:
    'live_reports/merge_live_reports/async/${taskId}/result',
  LIVE_REPORT_METADATA: 'live_reports_metadata',
  LIVE_REPORT_QUERY: 'live_reports/${parentId}/query',
  LIVE_REPORT_REFRESH_RESULTS: 'live_reports/refresh_results',
  LIVE_REPORT_RESULT: 'live_reports/results',
  LIVE_REPORT_RESULT_ROW_INFO: 'live_reports/${liveReportId}/results/metadata',
  LIVE_REPORT_RESULT_TSV: 'live_reports/${liveReportId}/results/tsv',
  LIVE_REPORT_RESULT_VERSION: 'live_reports/${liveReportId}/results/version',
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  LOG_RECORD: 'log/records',
  MANAGEABLE_FILE: 'manageable_file',
  MANAGEABLE_FILE_ALIAS_MAPPING: 'manageable_file/${id}/alias_mapping',
  MANAGEABLE_FILE_BY_ID: 'manageable_file/${id}',
  MANAGEABLE_FILE_IMPORT_SUMMARY: 'manageable_file/${id}/import_summary',
  MANAGEABLE_FILE_LOT_IMPORT_SUMMARY: 'manageable_file/lot/${id}/import_summary',
  MANAGEABLE_FILE_SEARCH: 'manageable_file/search',
  MODELS_DELETE_RESULTS: 'models/delete_results/async_task',
  MODELS_MARK_FRESH: 'models/mark_fresh',
  MPO: 'mpo',
  MPO_CHECK_NAME: 'mpo/check_name',
  OBSERVATION: 'observation',
  OBSERVATION_BATCH_ASYNC: 'observation/batch/async',
  OBSERVATION_COPY: 'observation/copy',
  OBSERVATION_SEARCH: 'observation/search',
  PARSE_HEADERS: 'import/parse_headers',
  PARSE_SDF_CSV_FILE: 'import/parse',
  PLDB_STRUCTURE: 'structure',
  PLOT: 'plot',
  PROJECT: 'projects',
  PROPERTY: 'models/model',
  RATIONALE: 'rationales',
  REACTION: 'reactions',
  REALTIME_PROPERTY_RESULT: 'property_calculation',
  RUN_CTR_MODEL: 'models/run_click_to_run/async_task',
  RUN_MODEL: 'models/run_model/async_task',
  R_GROUPS: 'r_groups/search',
  SCAFFOLDS: 'scaffolds',
  SCAFFOLDS_RGROUP: 'scaffolds/${id}/rgroup',
  STRUCTURE: 'threed_structures',
  STRUCTURES_RENDER: 'structures/render',
  SUBSCRIPTION: 'subscription',
  SYSTEM_COLUMN: 'system_column',
  THREE_D_STRUCTURE_GROUP: 'threed_structures/search/structure_groups',
  USER: 'users/current',
  USERS_PROJECT: 'users/project',
  VIZ3D_VISUALIZATION: 'viz3d/visualization',
  WEBSOCKET: 'websocket',
  XTALS: 'xtals',
  XTALS_EXPORT: 'xtals/export/async_task',
};

export default ApiEndpoints;
